import React from "react";
import { Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import * as _ from "lodash";

export interface Post {
  path: string
  tags: string[]
  title: string
  date: string
  niceDate: string
  body: any
}

interface Props {
  post: Post
  excerpt: boolean
}

export default ({ post, excerpt = false }: Props) => {
  return (
    <article className="Post">
      <header className="Post-header">
        <h1 className="Post-title">
          <Link to={post.path}>{post.title}</Link>
        </h1>
      </header>
      <section className="Post-meta">
        <time dateTime={post.date} title={post.date}>{post.niceDate}</time>&mdash;
        <nav>
          <ul className="Post-tags">
            {post.tags.map(tag => (
              <li className="Post-tag">
                <Link to={`/tags/${_.kebabCase(tag)}`}>{tag}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </section>
      {!excerpt &&
        <section className="Post-body">
          <MDXRenderer>{post.body}</MDXRenderer>
        </section>
      }
    </article>
  )
};

